import { graphql, Link } from 'gatsby';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useFlexSearch } from 'react-use-flexsearch';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import Layout from '../components/Layout';
import Tags from '../components/Tags';
import Search from '../components/Search';

const unFlattenResults = (results) =>
    results.map((post) => {
        const { slug, title, description, image } = post;

        return {
            node: {
                fields: { slug },
                frontmatter: {
                    title,
                    description,
                    featuredimage: JSON.parse(image),
                },
            },
        };
    });

const BlogListTemplate = ({
    posts,
    group,
    pages,
    currentPage,
    index,
    store,
}) => {
    let query = '';
    let heading = "What's new?";

    const _window = typeof window !== 'undefined' ? window : null;

    if (_window) {
        const { search } = _window.location;
        query = new URLSearchParams(search).get('query') || '';
    }
    const [searchQuery, setSearchQuery] = useState(query);

    const results = useFlexSearch(query, index, store);

    if (query !== '' && results.length === 0 && searchQuery === query) {
        heading = `We haven't found any posts matching ${searchQuery}`;
    }

    if (query !== '' && results.length > 0 && searchQuery === query) {
        heading = `We have found ${results.length} post(s) matching ${searchQuery}`;
    }

    if (searchQuery === '' || searchQuery !== query) {
        query = '';
    }

    const blogPosts = query === '' ? posts : unFlattenResults(results);

    return (
        <div className="section--green section--with-mask mask-four">
            <div className="container">
                <section className="section sitteroo-search-container p-5">
                    <h1 className="sitteroo-h2 pt-5">
                        {heading}
                    </h1>
                    <div className="sitteroo-search pt-5">
                        <Search
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                        />
                    </div>
                </section>

                <section className="section">
                    <Tags group={group} color="white" />
                </section>

                <section className="section">
                    <div className="container">
                        <div className="content">
                            <div className="columns is-multiline is-justify-content-space-between sitteroo">
                                {blogPosts &&
                                    blogPosts.map(({ node: post }, idx) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <div
                                            className="
                                                column
                                                card
                                                m-3
                                                p-3
                                                is-4-desktop
                                                is-narrow-desktop
                                                is-5-tablet
                                                "
                                            key={`blog-${idx}`}
                                        >
                                            <div className="card-image">
                                                <figure className="image">
                                                    <PreviewCompatibleImage
                                                        imageInfo={{
                                                            image: post
                                                                .frontmatter
                                                                .featuredimage,
                                                            alt: `featured image thumbnail for post ${post.frontmatter.title}`
                                                        }}
                                                    />
                                                </figure>
                                            </div>
                                            <div className="card-content">
                                                <div className="media">
                                                    <div className="media-content">
                                                        <h2>
                                                            <Link
                                                                className="sitteroo-blog-list-heading"
                                                                to={
                                                                    post.fields
                                                                        .slug
                                                                }
                                                            >
                                                                {
                                                                    post
                                                                        .frontmatter
                                                                        .title
                                                                }
                                                            </Link>
                                                        </h2>
                                                    </div>
                                                </div>

                                                <div className="content text-cut">
                                                    {
                                                        post.frontmatter
                                                            .description
                                                    }
                                                    <br />
                                                </div>
                                            </div>
                                            <footer className="card-footer">
                                                <div className="card-footer-item is-justify-content-flex-end is-justify-content-center-mobile">
                                                    <Link
                                                        className="button is-rounded is-large is-responsive bg-salmon is-primary "
                                                        to={post.fields.slug}
                                                        aria-label={
                                                            post.excerpt
                                                        }
                                                    >
                                                        read more
                                                        <span className="is-sr-only">
                                                            {
                                                                post.frontmatter
                                                                    .description
                                                            }
                                                        </span>
                                                    </Link>
                                                </div>
                                            </footer>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </section>
                {/* This one is to stop weird issue on mobile, when using finger you can drag viewport left and right */}
                <section style={{ maxWidth: '99%' }}>
                    <div className="containe pt-6 pb-6">
                        <nav
                            className="pagination is-centered"
                            role="navigation"
                            aria-label="pagination"
                        >
                            <ul className="pagination-list">
                                {query === '' &&
                                    pages > 0 &&
                                    Array.from({ length: pages }, (_, i) => (
                                        <li key={i}>
                                            <Link
                                                key={`pagination-number${i}`}
                                                to={`/${
                                                    i === 0
                                                        ? 'blog/'
                                                        : `blog/${i}/`
                                                }`}
                                                aria-label={`Goto page ${i}`}
                                                className={`pagination-link ${
                                                    currentPage === i
                                                        ? 'is-current'
                                                        : ''
                                                }`}
                                            >
                                                {i + 1}
                                            </Link>
                                        </li>
                                    ))}
                            </ul>
                        </nav>
                    </div>
                </section>
            </div>
        </div>
    );
};

const BlogList = ({ data, pageContext }) => {
    const {
        blogposts: { edges },
        tags: { group },
        searchResults: { index, store },
    } = data;

    const { currentPage, numPages } = pageContext;

    return (
        <Layout>
            <Helmet
                titleTemplate="%s"
                title={`Sitteroo blog posts - Page ${currentPage + 1} of ${numPages + 1}`}
            >
                <meta
                    name="description"
                    content={`List of blog posts for modern parents. Page ${currentPage + 1} of ${numPages + 1}`}
                />
                <meta
                    name="og:description"
                    content={`List of blog posts for modern parents. Page ${currentPage + 1} of ${numPages + 1}`}
                />
            </Helmet>

            <BlogListTemplate
                index={index}
                store={store}
                posts={edges}
                group={group}
                pages={numPages}
                currentPage={currentPage}
            />
        </Layout>
    );
};

BlogList.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.object,
        pageContext: PropTypes.object,
    }),
};

export default BlogList;

export const blogListQuery = graphql`
    query blogListQuery($skip: Int!, $limit: Int!) {
        searchResults: localSearchPages {
            index
            store
        }
        blogposts: allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        templateKey
                        date(formatString: "MMMM DD, YYYY")
                        description
                        featuredimage {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 447
                                    quality: 100
                                    layout: CONSTRAINED
                                    placeholder: BLURRED
                                )
                            }
                        }
                    }
                }
            }
            group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }
        tags: allMarkdownRemark(limit: 1000) {
            group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }
    }
`;

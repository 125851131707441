import React from 'react';
import search from '../img/design/search.svg';

const SearchBar = ({ searchQuery, setSearchQuery }) => (
    <form
        action="/blog"
        method="get"
        autoComplete="off"
        className="sitteroo-form"
    >
        <label htmlFor="header-search">
            <span className="is-hidden">Search blog posts</span>
        </label>

        <div className="field has-addons is-justify-content-center">
            <div className="control">
                <input
                    className="input is-medium"
                    value={searchQuery || ''}
                    onInput={(e) => setSearchQuery(e.target.value)}
                    type="search"
                    id="header-search"
                    placeholder="Search..."
                    name="query"
                />
            </div>
            <div className="control">
                <button type="submit" className="button is-medium">
                    <img src={search} alt="Sitteroo" />
                </button>
            </div>
        </div>
    </form>
);

export default SearchBar;

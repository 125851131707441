import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { kebabCase } from 'lodash';

const Tags = ({ group, color }) => (
    <ul className="sitteroo-tags">
        {group.map((tag) => (
            <li key={tag.fieldValue}>
                <Link
                    to={`/tags/${kebabCase(tag.fieldValue)}/`}
                    className={color}
                >
                    {tag.fieldValue}
                </Link>
            </li>
        ))}
    </ul>
);

Tags.propTypes = {
    group: PropTypes.arrayOf(
        PropTypes.shape({
            fieldValue: PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.string,
            ]),
            totalCount: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]),
        })
    ),
};

export default Tags;
